import bound from 'bound-decorator';
import { childTarget } from './../../utils/utils';
import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = [
    'addTicketInsuranceRadio',
    'donation',
    'donationLineItem',
    'donationLineItemValue',
    'customerFeeLineItem',    
    'conditionalLineItem',
    'cartTotal',
    'layupEstimate',
    'lineItem',
    'refundProtect',
    'refundProtectLineItem',
    'refundProtectLineItemValue',
  ];

  get currencyFormatter() {
    return (this._currencyFormatter ||= new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: this.currency,
    }));
  }

  get cartTotal() {
    return this.lineItemTargets.reduce(
      (total, lineItem) =>
        (total += this.tryParseFloat(lineItem.dataset.value)),
      0.0
    );
  }

  get currency() {
    return this.data.get('currency');
  }

  cartUpdated(e) {
    const { source, newValue, refundProtectSelected } = e.detail;

    const newAmount = this.tryParseFloat(newValue);

    switch (source) {
      case 'donation':
        this.updateLineItem(this.donationLineItemTarget, newAmount);
        this.donationTarget.value = newAmount;
        break;

      case 'customerFee':
        this.updateLineItem(this.customerFeeLineItemTarget, newAmount);
        break;
      case 'refundProtect':
        this.refundProtectTarget.value = refundProtectSelected ? 1 : 0;
        this.updateLineItem(this.refundProtectLineItemTarget, newAmount);
        this.updateLayupEstimate(refundProtectSelected); 
    }
  }

  updateLayupEstimate(refundProtectSelected) {
    this.layupEstimateTargets.forEach((target) => {
      const targetLabel = refundProtectSelected
        ? target.dataset.labelWithRefundProtect
        : target.dataset.labelWithoutRefundProtect;

      target.innerText = targetLabel;
    });
  }

  updateLineItem(lineItem, newAmount) {
    const lineItemValue = childTarget(this, 'lineItemValue', lineItem);

    lineItemValue.innerText = this.currencyFormatter.format(newAmount);
    lineItem.dataset.value = newAmount;

    this.updateCartDisplayValue();
    this.showHideConditionalLineItem(lineItem);
  }

  updateCartDisplayValue() {
    this.cartTotalTarget.innerText = this.currencyFormatter
      .format(this.cartTotal)
      .replace(/,/g, ' ');
  }

  showHideConditionalLineItem(lineItem) {
    const action =
      this.tryParseFloat(lineItem.dataset.value) > 0 ? 'remove' : 'add';
    lineItem.classList[action]('hidden');
  }

  showHideConditionalLineItems() {
    this.conditionalLineItemTargets.forEach((lineItem) =>
      this.showHideConditionalLineItem(lineItem)
    );
    this.updateCartDisplayValue();
  }

  tryParseFloat(value) {
    try {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    } catch (exception) {
      return 0;
    }
  }

  connect() {
    this.showHideConditionalLineItems();
  }
}

